import React from 'react'
import { graphql } from 'gatsby'

import Layout from '../molecules/layout'
import { Wrapper, Billboard, SEO } from '@renderbus/common/components'
import {
  BannerContainer,
  BannerButton,
  ActivityContainer,
  ActivityTitle,
  ActivityContainerArrowLeft,
  ActivityContainerArrowRight,
  JoinContent,
  JoinContainer,
  CouponImageContainer,
  CouponRulesContainer,
  CouponFlex,
  DoubleElTime,
  DoubleElTitle,
} from './2019-1111.atom'

import DoubleElTitleImage from '../images/2019-1111/banner-title.png'
import DoubleElTimeImage from '../images/2019-1111/banner-time.png'
import LoginIcon from '../images/2019-1111/login-icon.png'
import RechargeIcon from '../images/2019-1111/recharge-icon.png'
import CouponIcon from '../images/2019-1111/coupon-icon.png'
import CallIcon from '../images/2019-1111/call-icon.png'
import CouponImage from '../images/2019-1111/coupon.png'

class DoubleEl extends React.PureComponent {
  render() {
    const {
      location,
      data,
      pageContext: { topThreeShare },
    } = this.props
    return (
      <Layout location={location} topThreeShare={topThreeShare}>
        <SEO
          title='2019双十一云渲染充值优惠活动-Renderbus云渲染农场'
          keywords=''
          description='Renderbus云渲染农场效果图充值优惠活动,双十一大放价,充多少送多少,活动时间2019年10月22日-2019年11月22日.'
        />
        <BannerContainer>
          <DoubleElTitle src={DoubleElTitleImage} alt='双十一大放价，充多少送多少，低至3.5折！' />
          <DoubleElTime src={DoubleElTimeImage} alt='2019年10月22日-11月22日' />
          <BannerButton
            onMouseDown={() => {
              window.open('https://task.renderbus.com/center/user/payment', '_blank')
            }}
          >
            立即抢购
          </BannerButton>
          <Billboard fluid={data.banner.childImageSharp.fluid} />
        </BannerContainer>
        <Wrapper>
          <ActivityContainer>
            <ActivityTitle>
              <ActivityContainerArrowLeft />
              参与方式
              <ActivityContainerArrowRight />
            </ActivityTitle>
            <JoinContainer>
              <JoinContent as='a' href='https://task.renderbus.com/sso/register'>
                <img src={LoginIcon} alt='login' />
                <span>登录账户</span>
              </JoinContent>
              <JoinContent as='a' href='https://task.renderbus.com/center/user/topUp'>
                <img src={RechargeIcon} alt='recarge' />
                <span>充值</span>
              </JoinContent>
              <JoinContent as='a' href='https://task.renderbus.com/center/user/topUp'>
                <img src={CouponIcon} alt='coupon' />
                <span>获得渲染券</span>
              </JoinContent>
              <JoinContent
                as='a'
                href='http://wpa.b.qq.com/cgi/wpa.php?ln=2&uin=4000034560'
                target='_blank'
              >
                <img src={CallIcon} alt='call' />
                <span>联系客服</span>
              </JoinContent>
            </JoinContainer>
          </ActivityContainer>
          <ActivityContainer>
            <ActivityTitle>
              <ActivityContainerArrowLeft />
              活动规则
              <ActivityContainerArrowRight />
            </ActivityTitle>
            <CouponFlex>
              <CouponImageContainer src={CouponImage} alt='coupon' />
              <CouponRulesContainer>
                <li>充多少，送多少，最高赠送20000元渲染券；</li>
                <li>用户充值后，赠送渲染券将直接到账；</li>
                <li>赠送金额不可申请发票、提现，该优惠不与其他充值优惠活动同享；</li>
                <li>本次活动所充值金额仅限在效果图渲染使用，不支持退款和提现；</li>
                <li>如有任何问题，可以联系瑞云客服，此次活动数量有限，先到先得；</li>
                <li>
                  本次活动的最终解释权归瑞云科技所有，瑞云有权对恶意刷抢活动资源，利用资源从事违法违规行为的用户收回活动参与资格，并进行封号处理。
                </li>
              </CouponRulesContainer>
            </CouponFlex>
          </ActivityContainer>
        </Wrapper>
      </Layout>
    )
  }
}

export default DoubleEl

export const query = graphql`
  query {
    banner: file(relativePath: { regex: "/2019-1111/banner-bg.png/" }) {
      ...fluidImage
    }
  }
`
